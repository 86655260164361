/**
* Pure CSS ellipsis loading animation.
* 
* @mixin animation-swipe
* @see [CodePen]{@link https://codepen.io/thetallweeks/pen/yybGra}
*/
.ellipsis-loader:after {
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  animation: ellipsis steps(4, end) 900ms infinite;
  content: '\2026'; // ascii code for the ellipsis character
  width: 0px;
}

@keyframes ellipsis {
  to {
    width: 1.25em;
  }
}
