*,
*:before,
*:after {
  animation-duration: 0ms !important;
  transition-duration: 0ms !important;
}

#root > .App {
  max-height: none;
  overflow-y: visible;
}

#DebugMenu,
.uk-navbar > .uk-navbar-right,
#RefreshData,
#StatusAndActions .actions-available,
#AuthDetails hr,
#TheAppFooter {
  display: none;
}

#Navbar {
  height: auto !important;
}

.logo.uk-navbar-item {
  height: auto !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  padding-left: 0 !important;
}

.uk-navbar-app.uk-navbar-item {
  height: auto !important;
}

.pixie__label {
  border: 1px solid black !important;
}

.pixie__request-header-details {
  .pixie__request-header-case-number,
  .pixie__request-header-payer-name {
    width: auto;
  }

  .pixie__request-header-patient-name,
  .pixie__request-header-prescription,
  .pixie__request-header-payer-medical,
  #StatusAndActions .pixie-card > .uk-card > .uk-grid > div,
  .pixie__request-header-notes {
    width: auto;
  }

  #StatusAndActions > .uk-grid > div {
    width: 50%;
  }

  #AuthDetails > h2 {
    margin-top: 0;
  }
}

.request-data {
  padding-top: 0;

  & > .uk-grid > div {
    page-break-before: auto;
    width: 100%;
  }

  & > .uk-grid > div .uk-card .label,
  & > .uk-grid > div .uk-card .label + p {
    page-break-before: auto;
  }

  .uk-card > .uk-grid > div {
    width: 25%;
  }
}

.task-history {
  padding-top: 0;

  .uk-hidden {
    border-top: none !important;
    display: table-row !important;
    visibility: visible !important;

    & > td {
      padding-top: 0 !important;
    }

    & > td > div {
      white-space: pre-wrap !important;
    }
  }
}
