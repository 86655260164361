/**
* A Facebook-style loading placeholder animation.
* 
* @mixin animation-swipe
* @see [CodePen]{@link https://codepen.io/pjosh/pen/kXwaWZ}
*/
@mixin animation-swipe {
  @keyframes placeHolderSwipe {
    from {
      background-position: -600px 0;
    }
    to {
      background-position: 600px 0;
    }
  }

  animation: placeHolderSwipe 1s infinite linear forwards;
  background-repeat: no-repeat;
  background-color: #e2e2e2;
  background-image: linear-gradient(
    to right,
    #f3f3f3 0%,
    #f0f0f0 20%,
    #eeeeee 40%,
    #f8f8f8 100%
  );
}

.loading-animation-swipe {
  @include animation-swipe;
}
