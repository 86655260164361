html {
  perspective: 100vh;
  height: 100%;
}

body {
  color: var(--color-black--10);
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  font-size: 15px;
  overscroll-behavior: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-overflow-scrolling: touch;
}

// disable body overflow-y on Windows to prevent the additional
// and unnecessary scrollbar from showing when the offcanvas is open
body.uk-offcanvas-container[data-os='Windows'] {
  overflow-y: hidden;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

img {
  pointer-events: none;
}

// base heading styles
// @see https://cardinalhealth.atlassian.net/browse/PIX-153
h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--color-black--50);
  font-weight: bold;
  margin: 0 0 20px 0;
  text-transform: none;
}

// base paragraph (+ related elements) styles
// @see https://cardinalhealth.atlassian.net/browse/PIX-156
p,
ul,
ol,
dl,
pre,
address,
fieldset,
figure {
  margin: 0 0 20px 0;
}

// serperator margin styles for paragraphs and related elements
// @see https://cardinalhealth.atlassian.net/browse/PIX-156
* + p,
* + ul,
* + ol,
* + dl,
* + pre,
* + address,
* + fieldset,
* + figure {
  margin-top: 20px;
}

// base link (a) styles and states
// @see https://cardinalhealth.atlassian.net/browse/PIX-160
a,
a:link,
a:visited {
  color: var(--color-secondary);
  cursor: pointer;
  text-decoration: none;

  &:active,
  &:hover,
  &:focus {
    color: var(--color-secondary-100);
    text-decoration: none;
  }
}

// reset uikit's italic color
em {
  color: inherit;
}

// uikit framework base overrides
.uk-h1,
.uk-h2,
.uk-h3,
.uk-h4,
.uk-h5,
.uk-h6,
.uk-heading-2xlarge,
.uk-heading-large,
.uk-heading-medium,
.uk-heading-small,
.uk-heading-xlarge,
h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--color-black--50);
}
