.pixie-input-box {
  background: #fff;
  border-radius: 3px;
  border: 2px solid var(--color-tertiary--50);
  color: #666;
  display: inline-block;
  height: 44px;
  max-width: 100%;
  padding: 0 10px;
  transition: 0.2s ease-in-out;
  transition-property: color, background-color, border;
  vertical-align: middle;
  width: 100%;
  font-size: 16px;

  &:focus-within,
  &:focus-visible {
    border-color: var(--color-primary);
    box-shadow: 0px 0px 6px 0px rgba(81, 149, 213, 0.65);
    outline: none;
  }

  &:disabled {
    background-color: #f9f9f9;
    color: #999;
    border-color: #a8a8a8;
  }
}

.pixie-input-box-epa-modal {
  background: #fff;
  border-radius: 3px;
  border: 2px solid var(--color-tertiary--50);
  color: #666;
  display: inline-block;
  height: 44px;
  max-width: 100%;
  padding: 0 10px;
  transition: 0.2s ease-in-out;
  transition-property: color, background-color, border;
  vertical-align: middle;
  width: 100%;
  font-size: 16px;

  &:focus-within,
  &:focus-visible {
    border-color: var(--color-primary);
    box-shadow: 0px 0px 6px 0px rgba(81, 149, 213, 0.65);
    outline: none;
  }

  &:disabled {
    background-color: #f9f9f9;
    color: #999;
    border-color: #a8a8a8;
  }
}
