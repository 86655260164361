$Pixie__Toggle__Thumb-Size: 20px;
$Pixie__Toggle__BackgroundColor-Inactive--Default: #e6e6e6;
$Pixie__Toggle__BackgroundColor-Inactive--Hover: #c1bfbf;
$Pixie__Toggle__BackgroundColor-Active--Default: #609cd8;
$Pixie__Toggle__BackgroundColor-Active--Hover: #4575a5;

.react-toggle {
  .react-toggle-track {
    background-color: $Pixie__Toggle__BackgroundColor-Inactive--Default;
    height: 28px;
  }

  .react-toggle-thumb {
    border: none;
    border-color: $Pixie__Toggle__BackgroundColor-Inactive--Default;
    box-shadow: 0.75px 0.75px 0.15px 0.25px rgba(0, 0, 0, 0.15);
    top: 3.5px;
    left: 3px;
    width: $Pixie__Toggle__Thumb-Size;
    height: $Pixie__Toggle__Thumb-Size;
  }

  &.react-toggle--checked .react-toggle-track {
    background-color: $Pixie__Toggle__BackgroundColor-Active--Default;
  }

  &.react-toggle--checked .react-toggle-thumb {
    border-color: $Pixie__Toggle__BackgroundColor-Active--Default;
    left: 27px;
  }

  &:hover:not(.react-toggle--disabled) {
    .react-toggle-track {
      background-color: $Pixie__Toggle__BackgroundColor-Inactive--Hover;
    }

    .react-toggle-thumb {
      border-color: $Pixie__Toggle__BackgroundColor-Inactive--Hover;
    }
  }

  &.react-toggle--checked:hover:not(.react-toggle--disabled) {
    .react-toggle-track {
      background-color: $Pixie__Toggle__BackgroundColor-Active--Hover;
    }

    .react-toggle-thumb {
      border-color: $Pixie__Toggle__BackgroundColor-Active--Hover;
    }
  }
}
