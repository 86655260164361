:root {
  // breakpoints
  // ========================================================================
  --breakpoint-xsmall:      #{$Breakpoint-xSmall};
  --breakpoint-small:       #{$Breakpoint-Small};
  --breakpoint-medium:      #{$Breakpoint-Medium};
  --breakpoint-large:       #{$Breakpoint-Large};
  --breakpoint-xlarge:      #{$Breakpoint-xLarge};
  --breakpoint-xxlarge:     #{$Breakpoint-xxLarge};
  --breakpoint-hd:          #{$Breakpoint-HD};
  --breakpoint-5k:          #{$Breakpoint-5k};


  // colors
  // ========================================================================
  --color-primary:          #{$Color-Teal};
  --color-primary--100:     #{$Color-Teal};
  --color-primary--50:      #{$Color-MediumTeal};
  --color-primary--10:      #{$Color-LightTeal};

  --color-secondary:        #{$Color-Blue};
  --color-secondary--100:   #{$Color-DarkBlue};
  --color-secondary--alt1:  #{$Color-Pink};
  --color-secondary--alt2:  #{$Color-Orange};
  
  --color-tertiary:         #{$Color-DarkGray};
  --color-tertiary--100:    #{$Color-DarkGray};
  --color-tertiary--50:     #{$Color-Gray};
  --color-tertiary--10:     #{$Color-LightGray};

  --color-error:            #{$Color-Red};
  --color-danger:           #{$Color-Red};
  --color-alert:            #{$Color-Yellow};
  --color-amber:            #{$Color-Amber};
  --color-warning:          #{$Color-Yellow};
  --color-success:          #{$Color-Green};

  --color-black:            #{$Color-Black};
  --color-black--100:       #{$Color-Black};
  --color-black--50:        #{$Color-DarkCharcoal};
  --color-black--10:        #{$Color-CursedGray};

  --color-white:            #{$Color-White};
  --color-white--100:       #{$Color-White};
  --color-white--50:        #{$Color-Gray};
  --color-white--10:        #{$Color-CerebralGray};
  --color-button:           #{$Color-Button};
  --color-shield:           #{$Color-Shield};
  --color-priority:         #{$Color-LightBlue};
  --color-unit-gray:        #{$Color-UnitGray};
  --color-warm-gray:        #{$Color-WarmGray};
  --color-ebvButton:        #{$Color-EbvButton};
  --color-ebvBackground:    #{$Color-EbvBackground};
  --color-ebvBorder:        #{$Color-EbvBorder};
  --color-skyblue:          #{$Color-SkyBlue};

  --color-newBrandingPrimary:       #{$Color-BlueGreen};
  --color-borderGrey:               #{$Color-BorderGrey};
  --color-lineGrey:                 #{$Color-LineGrey};
  --color-primaryHover:             #{$Color-PrimaryHover};
  --color-Gray-10:                  #{$Color-Gray-10};
  --color-Gray-30:                  #{$Color-Gray-30};
}
