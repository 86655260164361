// these settings control the difference in space calculation variance

$QuestionsOffcanvas_TransitionDuration: 275ms;
$QuestionsOffcanvas_TransitionTiming: ease-out;
$QuestionsOffcanvas_TransitionProperty: width;
$QuestionsOffcanvas_Difference--Default: 15vw;
$QuestionsOffcanvas_Difference--Small: 350px;
$QuestionsOffcanvas_Difference--Medium: 20vw;
$QuestionsOffcanvas_Difference--Medium: 20vw;
$QuestionsOffcanvas_Difference--Large: 30vw;

// these styles allow for the UiKit's offcanvas to "push" the body over

body {
  will-change: $QuestionsOffcanvas_TransitionProperty;

  &.uk-offcanvas-container {
    overflow-y: scroll;
    transition-duration: $QuestionsOffcanvas_TransitionDuration;
    transition-timing-function: $QuestionsOffcanvas_TransitionTiming;
    transition-property: $QuestionsOffcanvas_TransitionProperty;
  }

  &.uk-offcanvas-flip.uk-offcanvas-container-animation {
    left: 0;
    width: calc(100vw - #{$QuestionsOffcanvas_Difference--Default});

    @media (min-width: 768px) {
      width: calc(100vw - #{$QuestionsOffcanvas_Difference--Small});
    }

    @media (min-width: 1200px) {
      width: calc(100vw - #{$QuestionsOffcanvas_Difference--Large});
    }

    @media (max-width: 767px) {
      width: 100vw;
    }
  }
}

// and these styles control the actual offcanvas div

#QuestionSet,
#AppealInput {
  &.uk-offcanvas {
    opacity: 0;
    transition: opacity $QuestionsOffcanvas_TransitionDuration ease-out;
    will-change: opacity;
  }

  .uk-offcanvas-bar {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    strong,
    em,
    i,
    label,
    input,
    textarea,
    button.uk-offcanvas-close {
      color: var(--color-secondary--100);
    }
  }

  .uk-offcanvas-bar {
    background-color: var(--color-tertiary--10);
    border-left: 1px solid var(--color-tertiary--50);
    min-width: 270px;
    width: $QuestionsOffcanvas_Difference--Default;
    transition-duration: $QuestionsOffcanvas_TransitionDuration;
    transition-timing-function: $QuestionsOffcanvas_TransitionTiming;
    transition-property: width, transform;
    transform: translateX(100%);
    will-change: width, transform;

    @media (min-width: 768px) {
      min-width: $QuestionsOffcanvas_Difference--Small;
    }

    @media (min-width: 1200px) {
      width: $QuestionsOffcanvas_Difference--Large;
    }

    @media (max-width: 767px) {
      width: 100vw;
    }
  }

  &.uk-offcanvas.uk-open {
    opacity: 1;
  }

  &.uk-offcanvas.uk-open .uk-offcanvas-bar {
    transform: translateX(0);
  }
}

// these styles control the overflow scrolling

#root > .App {
  max-height: 100vh;
  overflow-y: scroll;
}

// @todo move these styles to TextArea component

#QuestionSet,
#AppealInput {
  .uk-textarea {
    background-color: white;
    border-color: var(--color-tertiary--100);

    &:focus-within,
    &:focus-visible {
      border-color: var(--color-primary);
      box-shadow: 0px 0px 6px 0px rgba(81, 149, 213, 0.65);
      outline: none;
    }
  }
}
